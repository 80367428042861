import React from 'react';
import NavBar from './NavBar';
import TopWrap from './TopWrap';

const NavBarWrapper = () => {
  return (
    <div className="d-flex w-100 column">
      <TopWrap />
      <NavBar />
    </div>
  );
};

export default NavBarWrapper;
