import React, { useRef } from 'react';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import NavDropdown from './NavDropdown';
import NavItem from './NavItem';
import logo from '../../images/logo.webp';
import { checkPageLoading } from '../../state/auth/auth.actions';

const isBrowser = typeof window !== 'undefined';

const NavBar = ({ checkPageLoading }) => {
  const ref1 = useRef();
  return (
    <div className="light-gray-bg shadow-2 px-2 flex justify-content-between relative lg:static" style={{ minHeight: '80px' }}>
      <div className="nav-brand-skew d-flex align-items-center cursor
      animate__animated animate__bounceInLeft" onClick={() => {
        checkPageLoading(true);
        navigate('/');
      }}>
        <img src={logo} alt="logo" height={50} className="mr-0 lg:mr-6" style={{ width: '150px' }} />
      </div>
      <StyleClass nodeRef={ref1} selector="@next" enterClassName="hidden" leaveToClassName="hidden" hideOnOutsideClick>
        <a ref={ref1} className="cursor-pointer block lg:hidden text-700 pt-4">
          <i className="pi pi-bars text-4xl toggle-white"></i>
          <Ripple />
        </a>
      </StyleClass>
      <div className="align-items-center flex-grow-1 lg:pl-5 justify-content-between hidden lg:flex absolute lg:static w-full light-gray-bg left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul className="list-none p-0 m-0 flex lg:align-items-center h-100 select-none flex-column lg:flex-row">
          <NavItem linkText="Home" link="" animateIn="0.5s" />
          <NavItem linkText="About" link="about" animateIn="0.7s" />
          <NavItem linkText="Projects" link="projects" animateIn="0.7s" />
          <NavDropdown linkText="Products" link="products" animateIn="0.9s" />
          <li className="h-100" onClick={() => isBrowser ? document.querySelector('#footer').scrollIntoView() : null}>
            <a
              className="p-ripple flex px-6 p-3 pt-5 lg:px-3 lg:py-2
              align-items-center cursor-pointer h-100
              transition-duration-150 w-full nav-link
              animate__animated animate__bounceInRight"
              style={{ animationDuration: '1.1s' }}
            >
              <span>Contact Us</span>
              <Ripple />
            </a>
          </li>
        </ul>
      </div>
    </div>

  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(NavBar);
