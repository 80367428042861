import React, { useEffect, useState } from 'react';
import { Ripple } from 'primereact/ripple';
import { navigate } from 'gatsby-link';
import { connect } from 'react-redux';
import { checkPageLoading } from '../../state/auth/auth.actions';

const isBrowser = typeof window !== 'undefined';

const NavItem = ({ linkText, link, clickEvent, checkPageLoading, animateIn }) => {
  const [active, setActive] = useState(false);
  const handleClick = (ev) => {
    try {
      ev.preventDefault();
      if (clickEvent) clickEvent();
      if (link === '' && isBrowser && window.location.pathname !== '/') {
        checkPageLoading(true);
        navigate('/');
      }
      if (link) {
        if (link && link !== '' && isBrowser && !window.location.pathname.includes(link)) navigate(`/${link}`);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    isBrowser && window.location.pathname.includes(link) && link !== ''
      ? setActive(true)
      : isBrowser && window.location.pathname === '/' && link === ''
        ? setActive(true)
        : setActive(false);
  }, []);
  return (
    <li className="h-100" onClick={handleClick}>
      <a
        className={`p-ripple flex px-6 p-3 pt-5 lg:px-3 lg:py-2
        align-items-center cursor-pointer h-100 fw-500
        transition-duration-150 w-full nav-link
        animate__animated animate__bounceInRight
        ${active ? 'custom-active-nav' : ''}`}
        style={{ animationDuration: animateIn }}
      >
        <span>{linkText || ''}</span>
        <Ripple />
      </a>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(NavItem);
