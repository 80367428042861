import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaFacebookSquare } from 'react-icons/fa';

const TopWrap = () => {
  return (
    <div className="gray-bg" data-aos="fade-up" data-aos-duration="700">
      <div className="container pb-0 pt-1">
        <div className="row justify-content-between">
          <div className="col-md topper d-flex align-items-center">
            <div className="text">
              <p className="con mb-0 pb-0">
                <span className="white-c">Flower Business Park, Naivasha</span>
              </p>
              <p className="con mt-0 pt-0 mb-0 pb-0">
                <span className="white-c">Off Nairobi-Nakuru Highway</span>
              </p>
            </div>
          </div>
          <div className="col-md topper d-flex justify-content-lg-end">
            <div className="text me-4 d-flex align-items-center justify-content-lg-end">
              <p className="con cursor" onClick={(ev) => {
                ev.preventDefault();
                window.open('mailto:admin@ecoliteminerals.com');
              }}>
                <span className="green-c ft-700">Email Adddress: </span>
                <span className="white-c">admin@ecoliteminerals.com</span>
              </p>
            </div>
            <ul className="ftco-social-top d-flex align-items-center" style={{ listStyle: 'none' }}>
              <li><a href="https://www.facebook.com/ecolitemineralskenya/"
              target="_blank" rel="noreferrer"><i><FaFacebookSquare /></i></a></li>
              <li><a href="https://www.instagram.com/ecolitemineralske_/"
              target="_blank" rel="noreferrer"><i><BsInstagram /></i></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopWrap;
