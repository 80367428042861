import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import './layout.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'uikit/dist/css/uikit.css';
import 'uikit/dist/css/uikit-core.css';
import 'uikit/dist/js/uikit';
import 'uikit/dist/js/uikit-core';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import AOS from 'aos';
import { connect } from 'react-redux';
import SyncLoader from 'react-spinners/SyncLoader';
import { checkPageLoading, checkUser } from '../state/auth/auth.actions';
import NavBarWrapper from './navbar/NavBarWrapper';

const isBrowser = typeof window !== 'undefined';

const Layout = ({ children, pageLoading, checkPageLoading }) => {
  useEffect(() => {
    AOS.init({ once: true });
    checkPageLoading(true);
    setTimeout(() => checkPageLoading(false), 2000);
  }, []);
  return (
    <>
      {isBrowser ? <main>
        {pageLoading ? <div className="w-100 h-100vh d-flex center column linear-bg">
          <SyncLoader color={'#00b500'} background={'white'} customLoading={pageLoading} size={30} />
        </div> : <>
          <NavBarWrapper />
          {children}
        </>}
      </main> : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  pageLoading: state.auth.pageLoading,
  toast: state.auth.toast,
  user: state.auth.user
});

const mapDispatchToProps = (dispatch) => ({
  checkUser: (user) => dispatch(checkUser(user)),
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
