import React, { useEffect, useRef, useState } from 'react';
import { Ripple } from 'primereact/ripple';
import { StyleClass } from 'primereact/styleclass';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';
import { checkPageLoading } from '../../state/auth/auth.actions';

const isBrowser = typeof window !== 'undefined';

const NavDropdown = ({ linkText, link, checkPageLoading, animateIn }) => {
  const ref1 = useRef();
  const [active, setActive] = useState(false);
  useEffect(() => {
    isBrowser
    && (window.location.pathname.includes('vermiculite')
      || window.location.pathname.includes('peat-moss')
      || window.location.pathname.includes('cocopeat')
      || window.location.pathname.includes('perlite')
      || window.location.pathname.includes('firelighter'))
    && link !== ''
      ? setActive(true)
      : isBrowser && window.location.pathname === '/' && link === ''
        ? setActive(true)
        : setActive(false);
  }, []);
  const handleClick = (link) => {
    if (isBrowser && !window.location.pathname.includes(link)) {
      checkPageLoading(true);
      navigate(`/${link}`);
    }
  };
  return (
    <li className="lg:relative h-100">
      <StyleClass
        nodeRef={ref1}
        selector="@next"
        enterClassName="hidden"
        enterActiveClassName="scalein"
        leaveToClassName="hidden"
        leaveActiveClassName="fadeout"
        hideOnOutsideClick
      >
        <a
          ref={ref1}
          className={`p-ripple flex px-6 p-3 pt-5 lg:px-3 lg:py-2
        align-items-center cursor-pointer h-100
        transition-duration-150 w-full nav-link
        animate__animated animate__bounceInRight
        ${active ? 'custom-active-nav' : ''}`}
        style={{ animationDuration: animateIn }}
        >
          <span>{linkText || ''}</span>
          <i className="pi pi-angle-down ml-auto lg:ml-3"></i>
          <Ripple />
        </a>
      </StyleClass>
      <ul className="list-none py-3 px-6 m-0 lg:px-0 lg:py-0 border-round shadow-0 lg:shadow-2 lg:absolute light-gray-bg hidden origin-top w-full lg:w-15rem cursor-pointer">
        <li onClick={() => handleClick('vermiculite')}>
          <a className="p-ripple flex p-3 align-items-center hover:surface-100 toggle-gray transition-colors transition-duration-150 w-full">
            <span className="text-sm caps">Vermiculite</span>
            <Ripple />
          </a>
        </li>
        <li onClick={() => handleClick('peat-moss')}>
          <a className="p-ripple flex p-3 align-items-center hover:surface-100 toggle-gray transition-colors transition-duration-150 w-full">
            <span className="text-sm caps">Peat Moss</span>
            <Ripple />
          </a>
        </li>
        <li onClick={() => handleClick('cocopeat')}>
          <a className="p-ripple flex p-3 align-items-center hover:surface-100 toggle-gray transition-colors transition-duration-150 w-full">
            <span className="text-sm caps">Cocopeat</span>
            <Ripple />
          </a>
        </li>
        <li onClick={() => handleClick('perlite')}>
          <a className="p-ripple flex p-3 align-items-center hover:surface-100 toggle-gray transition-colors transition-duration-150 w-full">
            <span className="text-sm caps">Perlite</span>
            <Ripple />
          </a>
        </li>
        <li onClick={() => handleClick('firelighter')}>
          <a className="p-ripple flex p-3 align-items-center hover:surface-100 toggle-gray transition-colors transition-duration-150 w-full">
            <span className="text-sm caps">Firelighter</span>
            <Ripple />
          </a>
        </li>
      </ul>
    </li>
  );
};

const mapDispatchToProps = (dispatch) => ({
  checkPageLoading: (state) => dispatch(checkPageLoading(state))
});

export default connect(null, mapDispatchToProps)(NavDropdown);
